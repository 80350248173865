<template>
  <div class="container">
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">兑换列表</div>
      <div class="header-left">&nbsp;</div>
    </div>
    <div class="h90"></div>
    <div v-for="(item, i) in list" :key="i">
      <div class="goods-item">
        <div class="goods-item-l">
          <img v-if="item.prodPic" :src="item.prodPic" alt="" srcset="">
          <img v-else src="@/accesst/goods/bg.png" alt="" srcset="">
        </div>
        <div class="goods-item-r">
          <div class="g-title">{{ item.prodName || ' ' }}</div>
          <div class="g-date">{{ item.exchangeDate }}</div>
          <div class="g-address">{{ item.address }}</div>
          <div class="g-u">{{item.phone}} {{ item.consignee }}</div>
        </div>
      </div>  
    </div>
    <van-empty v-if="list && list.length === 0" description="暂无兑换记录" />
  </div>
</template>

<script>
import { exchangeList } from '@/api/h5'
import { Icon, Empty } from 'vant';
import moment from 'moment'

export default {
  components: { [Icon.name]: Icon, [Empty.name]: Empty },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,   // 禁止背景点击
      duration: 300,  //展示时间，0为持续展示
      overlay: true,  //是否显示背景遮罩层
    })
    exchangeList({pageSize: 100}).then(res => {
      this.list = res.list.map(v => {
        v.exchangeDate = moment().format('yyyy-MM-DD HH:mm:ss');
        return v
      });
    })
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  .h90 {
    height: .9rem;
  }
  .goods-item {
  margin: .2rem auto 0;
  width: 6.92rem;
  height: 2.67rem;
  background: #F6F8F9;
  border-radius: .08rem;
  display: flex;
  justify-content: space-between;
  .goods-item-l {
    width: 2.45rem;
    height: 2.67rem;
    // border-radius: .08rem 0 0 .08rem;
    img {
      width: 2.45rem;
      height: 2.67rem;
      border-radius: .08rem 0 0 .08rem;
    }
  }
  .goods-item-r {
    width: 4.48rem;
    height: 2.67rem;
    
    .g-title {
      padding: .23rem .25rem 0;
      overflow: hidden;
      height: .42rem;
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: .42rem;
    }
    .g-date {
      padding: .12rem .25rem .25rem;
      font-size: .23rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .g-address {
      padding: 0 .25rem;
      height: .73rem;
      font-size: .23rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: .37rem;
      overflow: hidden;
    }
    .g-u {
      padding: .06rem .25rem;
      font-size: .23rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }
}
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      width: .3rem;
    }
  }
}
</style>